import { FC } from "react";

import { Box } from "@chakra-ui/react";

type TDiscountLabelProps = {
  text: string;
  rounded?: boolean;
};

const DiscountLabel: FC<TDiscountLabelProps> = ({ text, rounded }) => {
  return (
    <Box
      bg="primary.500"
      color="white"
      fontWeight="bold"
      fontSize="textSize.regular"
      py="1"
      px="3"
      borderRadius={rounded ? "4px" : "0px"}
    >
      {text}
    </Box>
  );
};

DiscountLabel.defaultProps = {
  rounded: false,
};

export default DiscountLabel;
